
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  import { Shipment } from '@/store/modules/Shipments/ShipmentsModule';

  export default defineComponent({
    name: 'shipments-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup(props) {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitVideoButton = ref<HTMLButtonElement | null>(null);
      const loading = ref(false);
      const createdShipment = ref<Shipment | null>(null);
      const selectedOffice = ref<string>('');
      const selectedDriver = ref<string>('');
      const selectedItem = ref<string>('');
      const selectStatus = ref<string>('');
      const videoInput = ref('');
      const selectedUnassignedPackages = ref<Array<string>>([]);
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const shipmentsSchema = Yup.object().shape({
        officeId: Yup.lazy((value) => {
          if (selectedItem.value === '') {
            return Yup.string()
              .required(() => translate('OFFICE_IS_REQUIRED_FIELD'))
              .label('Office');
          }
          return Yup.string().label('To');
        }),
        driverId: Yup.string()
          .notOneOf([''], () => translate('DRIVER_IS_REQUIRED_FIELD'))
          .required(() => translate('DRIVER_IS_REQUIRED_FIELD'))
          .label('Driver'),
        packages: Yup.number()
          .min(1, 'At least one package is required')
          .label('Packages'),
      });

      const driverTitles = ref<string>('');
      watch(
        () => selectedOffice.value,
        async (newSelectedOffice) => {
          driverTitles.value = offices
            .filter((office) => office.id === newSelectedOffice)
            .map((office) => {
              const matchedDriver = drivers.find(
                (driver) => driver.id === office.driverId
              );
              return matchedDriver ? matchedDriver.id : '';
            });
        }
      );

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        delete values.packages;

        if (selectedOffice.value) values.officeId = selectedOffice.value;
        else delete values.officeId;
        values.packageIds = packagesArray.value;
        const result = await store.dispatch(
          Actions.CREATE_UNASSIGNED_PACKAGE,
          values
        );
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          createdShipment.value = result;
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_SHIPMENT'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'unassigned-listing' });
          });
        }
      };
      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('shipmentCreating'), [
          translate('packages'),
        ]);
        reinitializeComponents();
      });
      const Office = await store.dispatch(Actions.GET_SHIPMENT_GW_OFFICES);
      const offices = Office.data?.filter((office) => office.deletedat == null);

      await store.dispatch(Actions.GET_ALL_SHIPMENT_STATUSES, 'gw');
      const Driver = await store.dispatch(Actions.GET_ALL_DRIVERS);
      const drivers = Driver.data.data?.filter(
        (driver) => driver.deletedAt == null
      );
      await store.dispatch(Actions.GET_ALL_UNASSIGNED_PACKAGES);

      const packagesArray = computed(() => store.getters.getPackagesArray);

      return {
        submitVideoButton,
        driverTitles,
        onSubmitCreate,
        submitButton,
        translate,
        shipmentsSchema,
        goBack,
        can,
        selectedItem,
        loading,
        createdShipment,
        offices,
        drivers,
        packagesArray,
        // shipmentStatuses: computed(() => store.getters.shipmentStatusesList),
        selectedOffice,
        selectedDriver,
        selectStatus,
        videoInput,
        selectedUnassignedPackages,
      };
    },
  });
